const MESSAGE = {
  EN: {
    rank: 'RANK',
    area: 'AREA'
  },
  TH: {
    rank: 'อันดับ',
    area: 'พื้นที่'
  }
}

export default MESSAGE
