import styled from 'styled-components'

export default styled.div`
  width: 100%;
  flex: 1;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  .violation-ranking-wrapper {
    height: 255px;
    background: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    .violation-ranking {
      height: 100%;
      display: flex;
      flex-direction: column;
      .rank-row {
        height: 85px;
        display: grid;
        grid-template-columns: 33% 34% 33%;
        grid-template-rows: 100%;
        .rank-wrapper {
          margin-top: 0.5;
          margin-left: 20px;
        }
        .rank-wrapper,
        .area-wrapper {
          align-self: center;
          .rank-label {
            transform: rotate(-360deg);
            opacity: 0.5;
            font-family: 'Prompt', sans-serif;
            font-weight: bold;
            font-size: 12px;
            line-height: 0.63;
            color: ${(props) => props.theme.darkBackground};
            margin-bottom: 6px;
          }
          .rank-data,
          .area-data {
            color: ${(props) => props.theme.darkBackground};
            font-family: 'Prompt', sans-serif;
            font-weight: bold;
            font-size: 20px;
          }
          .area-data {
            max-width: 160px;
          }
        }
        .total-wrapper {
          justify-self: center;
          align-self: center;
        }
      }
      .divider {
        height: 1px;
        width: 275px;
        opacity: 0.5;
        background-color: ${(props) => props.theme.greyDisabled};
        align-self: center;
      }
    }
  }
`
