/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@material-ui/core/Tooltip'
import NumberBox from '../NumberBox/Loadable'
import SkeletonLoading from '../SkeletonLoading/Loadable'
import CardWrapper from '../CardWrapper/Loadable'

import ViolationCountRankingStyled from './styledComponent'
import { ENGLISH_LANGUAGE } from '../../utils'

import MESSAGE from './message'

const FIRST_RANK_INDEX = 0
const SECOND_RANK_INDEX = 1
const THIRD_RANK_INDEX = 2

class ViolationCountRanking extends React.PureComponent {
  getRankLabel(label, index) {
    return (
      <div className="rank-label text-style" id={`lbl-${label.toLowerCase()}-${this.getRank(index)}`}>
        {this.getMessage(label.toLowerCase())}
      </div>
    )
  }

  getRank(index) {
    switch (index) {
      case FIRST_RANK_INDEX:
        return '1st'
      case SECOND_RANK_INDEX:
        return '2nd'
      case THIRD_RANK_INDEX:
        return '3rd'
      default:
        return '1st'
    }
  }

  renderDivider(index) {
    let output = null
    if (index < THIRD_RANK_INDEX) {
      output = <div className="divider" />
    }
    return output
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.language || ENGLISH_LANGUAGE]
    return msg[property]
  }

  getRankViolationRow(row, index) {
    return (
      <React.Fragment key={`${row.area}-index`}>
        <div className="rank-row">
          <div className="rank-wrapper">
            {this.getRankLabel('RANK', index)}
            <div className="rank-data" id={`lbl-${this.getRank(index)}`}>
              {this.getRank(index)}
            </div>
          </div>
          <div className="area-wrapper">
            {this.getRankLabel('AREA')}
            <Tooltip title={row.area}>
              <div className="area-data text-style prevent-text-overflow" id={`lbl-area-data-${this.getRank(index)}`}>
                {row.area}
              </div>
            </Tooltip>
          </div>
          <div className="total-wrapper">
            <NumberBox text={row.count} id={`lbl-total-${this.getRank(index)}`} />
          </div>
        </div>
        {this.renderDivider(index)}
      </React.Fragment>
    )
  }

  getRankViolationCount() {
    const { data } = this.props
    return (
      <div className="violation-ranking">
        {data.map((rank, index) => {
          return this.getRankViolationRow(rank, index)
        })}
      </div>
    )
  }

  getViolationRankingContent() {
    return (
      <CardWrapper cardTitle={this.props.title}>
        <div className="violation-ranking-wrapper">{this.getRankViolationCount()}</div>
      </CardWrapper>
    )
  }

  getContent() {
    const content = this.props.isLoading ? <SkeletonLoading /> : this.getViolationRankingContent()
    return content
  }

  render() {
    return <ViolationCountRankingStyled>{this.getContent()}</ViolationCountRankingStyled>
  }
}

ViolationCountRanking.propTypes = {
  title: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isLoading: PropTypes.bool,
  language: PropTypes.string,
  oneArea: PropTypes.bool
}

export default ViolationCountRanking
